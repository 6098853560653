<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[11]}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<div ref="box" class="course_box" v-infinite-scroll="loadpdf" 
						:infinite-scroll-distance="500"
						:infinite-scroll-disabled="load_disabled">
						<!-- 新手教程内容 -->
						<pdf ref="pdf" :src="src" :page="page" @error="error" @num-pages="getTotalPages"></pdf>
					
						<pdf 
						v-for="i in count"
						:key="i"
						:src="src"
						:page="i+1"></pdf>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content content_phone">
				<div class="content-container">
					<div class="course_box">
						<!-- 新手教程内容 -->
						<pdf
						v-for="i in numPages"
						:key="i"
						:src="src"
						:page="i"></pdf>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'course.styl';
</style>

<script>
	import pdf from 'vue-pdf'
	import bps_zh from "@/assets/pdfFile/toturial_zh.pdf"
	import bps_en from "@/assets/pdfFile/toturial_en.pdf"
	import bps_ko from "@/assets/pdfFile/toturial_ko.pdf"
	import { Loading } from 'element-ui';
	import { mapGetters } from 'vuex'
	let that;
	export default ({
		name: 'course',
		// inject:['reloadApp'],
		components:{
			pdf
		},
		data() {
			return {
				page:1,
                numPages: 0,

				loading:'',
				timeR:'',
				count:0,
			}
		},
		computed: {
			...mapGetters(['lang','isMobile']),
			src(){
				return this.lang=='zh'? bps_zh:
				this.lang=='ko'? bps_ko:bps_en  
			},
			load_disabled (){
				return this.count>=this.numPages-1
			},
		},
		created(){
			that=this;
		},
		mounted() {
			if(this.isMobile==1){
				if(this.loading){
					this.loading.close();
				}
				this.loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				this.$nextTick(()=>{
					this.handelPdf()
				})
			}
		},
		methods: {
			// PC
			loadpdf () {
				this.count += 1
			},
			error(err){
				console.log('err',err)
			},
			getTotalPages(e){
				// console.log(e)
				if(!e){
					if(this.loading){
						this.loading.close();
					}
					this.loading = Loading.service({
						target: '.main',
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						customClass: 'apploading',
					});
				}else{
					if(this.loading){
						// 回到顶部
						this.$refs.box.scrollTo(0,0);

						this.loading.close();
					}
					this.numPages = e
				}
			},
			
			// 移动判定
			// 获取pdf总页数
			async handelPdf() {
				// pdf
                this.numPages = await pdf.createLoadingTask(that.src).promise.then(pdf => {
					// return pdf.numPages
					return new Promise(
						resolve=>resolve(pdf.numPages)
					)
                }).catch(err=>{
					console.log('err',err)
                    return 0
				})
				
				that.$nextTick(()=>{
					if(that.timeR){
						clearTimeout(that.timeR)
						that.timeR='';
					}
					that.timeR=setTimeout(()=>{
						if(that.loading){
							that.loading.close();
						}
					},300)
				});
            },
		},
		watch: {
			lang() {
            	// this.handelPdf()
				// this.reloadApp();
				this.count=0;
			},
		},
		beforeDestroy() {
			if(this.timeR){
				clearTimeout(this.timeR)
			}
			if(this.loading){
				this.loading.close();
			}
		},
	})
</script>
